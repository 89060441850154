<template>
  <v-container class="mt-16">
    <div class="">
      <div v-if="ready">
        <v-card flat>
          <v-card-title class="d-flex">
            <h2>Batch Imports</h2>
            <v-spacer></v-spacer>
            <add-batch-import-modal />
            <v-btn
              color=""
              class=""
              icon
              x-large
              @click="refresh"
              :loading="loading"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-data-table :items="import_batches" :headers="headers">
              <template v-slot:item.actions="{ item }">
                <edit-batch-import-modal :item="item"></edit-batch-import-modal>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="d-flex justify-center">
        <h2 class="text-center mt-10 mb-10">Data Table is loading...</h2>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addBatchImportModal from "@/components/BatchImport/addBatchImportModal.vue";
import EditBatchImportModal from "@/components/BatchImport/editBatchImportModal.vue";
export default {
  components: { addBatchImportModal, EditBatchImportModal },
  data() {
    return {
      loading: false,
      ready: true,
      headers: [
        { text: "", value: "actions" },
        { text: "id", value: "id" },
        { text: "Batch Name", value: "batch_description" },
        { text: "Supplier", value: "supplier.supplier_name" },
        { text: "Capital Amount", value: "capital_amount" },
        { text: "Gross Amount", value: "gross_amount" },
        { text: "Net Amount", value: "net_amount" },
        { text: "Date Ordered", value: "date_ordered" },
        { text: "Date Arrived", value: "date_arrived" },
        { text: "No of Associated Units", value: "no_of_associated_units" },
        {
          text: "Breakeven Date",
          value: "break_even_date",
        },
        { text: "Sold out Date", value: "sold_out_date" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      import_batches: "importBatch/import_batches",
    }),
  },
  methods: {
    ...mapActions({
      get_import_batches: "importBatch/get_import_batches",
    }),
    refresh() {
      this.loading = true;
      this.get_import_batches().then(() => {
        this.loading = false;
      });
    },
  },
  created() {
    if (this.import_batches.length == 0) {
      this.ready = false;

      this.get_import_batches().then(() => {
        this.ready = true;
      });
    }
  },
};
</script>

<style></style>
